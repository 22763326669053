@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.logo {
  width: 133px;
  height: 133px;
}

ul {
  list-style-type: none;
}

a:link {
  text-decoration: none;
}

.jumbo-home {
  background-image: url('./img/hard-hat.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.jumbo-about {
  background-image: url('https://cdn.pixabay.com/photo/2017/06/30/12/10/shipyard-2458150_1280.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.jumbo-train {
  background-image: url('https://cdn.pixabay.com/photo/2016/11/02/01/59/factory-1790258_1280.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.jumbo-consult {
  background-image: url('https://cdn.pixabay.com/photo/2017/08/07/18/27/businessman-2606502_1280.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.jumbo-construct {
  background-image: url('https://cdn.pixabay.com/photo/2016/10/19/12/38/industry-1752876_1280.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.jumbo-employers {
  background-image: url('https://images.unsplash.com/39/lIZrwvbeRuuzqOoWJUEn_Photoaday_CSD%20(1%20of%201)-5.jpg?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.jumbo-contact {
  background-image: url('https://cdn.pixabay.com/photo/2016/10/19/12/38/industry-1752876_1280.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 550px;
}

.bold {
  color: black;
}

.contact-card {
  width: 300px;
}

.const-image {
  width: 400px;
}

.home-text {
  font-family: 'Roboto', sans-serif;
}

.training-list {
  width: 300px;
  font-size: 1.4em;
}

/* About */
.about-image {
  width: 300px; /* You can set the dimensions to whatever you want */
  height: 200px;
  object-fit: cover;
}

.danger-img {
  height: 200px;
}

.safety-image {
  padding-top: 90px;
}

@media (max-width: 800px) {
  .carousel {
    width: 600px;
    height: 500px;
    margin: auto;
    overflow: hidden;
  }

  .safety-image {
    padding-top: 0;
  }
  .ladder-image {
    padding-top: 45px;
  }

  .danger-img {
    height: 150px;
  }
}
@media (max-width: 700px) {
  .carousel {
    width: 500px;
    height: 400px;
    margin: auto;
    overflow: hidden;
  }

  .safety-image {
    padding-top: 0;
  }
  .ladder-image {
    padding-top: 45px;
  }
  .danger-img {
    height: 100px;
  }
}

@media (max-width: 500px) {
  .carousel {
    width: 400px;
    height: 250px;
    margin: auto;
    overflow: hidden;
  }

  .safety-image {
    padding-top: 0;
  }

  .ladder-image {
    padding-top: 45px;
  }
}
